<template>
	<b-card class="alarms-list" @click.stop.prevent="toggle()">
		<template #header>
			<span id="responsive-label">Alarmes Ativos</span>
			<span class="float-right">
				<clock-icon/> <span>{{ date }}</span>
			</span>
			<ChevronRightIcon v-show="isOpen" class="responsive" @click.stop.prevent="toggle()" color="black" />
			<ChevronDownIcon v-show="!isOpen" class="responsive" @click.stop.prevent="toggle()" color="black" />
		</template>
		<transition name="fadeHeight">
		<b-card-text class="text-left" :class="mobileView ?'p-3':''" v-show="!isOpen" >
			<ul v-if="alarms.length" class="mb-0">
				<li v-for="(alarm, idx) in alarms" :key="idx">
					<div class="alarm mb-2">
						<div
							class="state text-center"
							:class="{
								'bg-danger': alarm.classe === 'Alarme',
								'bg-warning': alarm.classe === 'Alerta'
							}"
						>
							{{ alarm.state }}
						</div>
						<div class="details text-ellipsis ml-2" :title="`${alarm.descricao}`">
							<span>{{ alarm.tag }} ({{ alarm.descricao }})</span><br/>
							<small>{{ alarm.maquina }} - {{ alarm.data }}</small>
						</div>
					</div>
				</li>
			</ul>
			<div v-if="!alarms.length" class="alert alert-warning reveal-animation mb-0">
				Nenhum alarme ativo no momento.
			</div>
		</b-card-text>
		</transition>
	</b-card>
</template>

<script>
	import dayjs from "dayjs";

	import { AlarmesService } from "@/services/alarmes";

	export default {
		props: {
			idMaquina: {
				type: Number,
				required: false
			}
		},

		data () {
			return {
				alarms: [],
				date: "Carregando...",
				alarmsListInterval: null,
				alarmesService: new AlarmesService(),
				isOpen: false,
				mobileView: false,
				atualizandoAlarmes: false
			};
		},

		mounted () {
			this.updateAlarmsList();
			this.alarmsListInterval = setInterval(this.updateAlarmsList.bind(this), 10 * 1000);
		},

		beforeDestroy () {
			clearInterval(this.alarmsListInterval);
		},

		methods: {
			async updateAlarmsList () {
				try {
					if (this.atualizandoAlarmes == true)
						return;

					this.atualizandoAlarmes = true;

					const maquina = {
						id: this.idMaquina
					};

					this.alarms = await this.alarmesService.listaAlarmesAtivos(maquina, false) || [];
					for (const alarm of this.alarms)
						alarm.data = dayjs(alarm.data).format("DD/MM/YYYY HH:mm:ss");

					this.date = dayjs().format("DD/MM/YYYY HH:mm:ss");
					this.atualizandoAlarmes = false;
				} catch (error) {
					this.atualizandoAlarmes = false;
					console.error(error);
					this.$swal({
						title: "Falha ao buscar alarmes!",
						type: "error",
						html: `<p>${(error.response && error.response.data.error) || error.message}</p>`,
						confirmButtonText: "Fechar",
						confirmButtonColor: "#6c757d"
					});
				}
			},

			handleView () {
				this.mobileView = window.innerWidth <= 850;

				if (window.innerWidth > 850) {
					this.isOpen = false;
				} else { this.isOpen = true; }
			},

			toggle () {
				if (this.mobileView) {
					this.isOpen = !this.isOpen;
				} else {
					this.isOpen = false;
				}
			}
		},

		created () {
			this.handleView();
			window.addEventListener("resize", this.handleView);
		},

		watch: {
			idMaquina: "updateAlarmsList"
		}
	};
</script>

<style lang="scss" scoped>
	.card-header span {
		line-height: 27px;
		vertical-align: middle;
	}

	.card-body {
		height: 100%;
		overflow-y: auto;
	}

	ul {
		list-style: none;
		padding: 0;

		li:last-child .alarm {
			margin-bottom: 0 !important;
		}
	}

	.alarm {
		width: 100%;
		display: flex;
		flex-direction: row;

		.state {
			border-radius: 5px;
			width: 48px;
			line-height: 48px;
			background: var(--secondary);

			&:not(.bg-warning) {
				color: white;
			}

			&.on {
				background: var(--danger);
			}
		}

		.details {
			width: calc(100% - 40px);
		}
	}

	.responsive {
		display: none;
	}

	.fadeHeight-enter-active,
	.fadeHeight-leave-active {
		transition: all 0.5s;
		max-height: 700px;
	}

	.fadeHeight-enter,
	.fadeHeight-leave-to{
		opacity: 0;
		max-height: 0px;
	}

	@media (max-width: 850px) {
		.alarms-list {
			position: relative !important;
			width: calc(100% - 2rem) !important;
			margin-left: auto;
			margin-right: auto;
			margin-top: calc(66px + 1rem);
			margin-bottom: 1rem;
		}

		.card-body {
			padding: 0px;
		}

		.show-data {
			display: none !important;
		}

		.responsive {
			display: inline;
			float: left;
		}

		#responsive-label {
			padding-left: 10px;
		}
	}
</style>
