import { render, staticRenderFns } from "./AlarmsList.vue?vue&type=template&id=5f5c8928&scoped=true&"
import script from "./AlarmsList.vue?vue&type=script&lang=js&"
export * from "./AlarmsList.vue?vue&type=script&lang=js&"
import style0 from "./AlarmsList.vue?vue&type=style&index=0&id=5f5c8928&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f5c8928",
  null
  
)

export default component.exports